import React from 'react'
import {Link} from 'gatsby'
import Seo from "../components/seo"
import BlogLayout from "../components/BlogLayout"
import ProjectSnapshot from "../components/ProjectSnapshot"
import { StaticImage } from "gatsby-plugin-image"
import logos from "../components/CodingLogos"
import ForMeToDoLogo from "../images/projects/formetodo/ForMeToDo_LogoNoBack.svg"

const project_ForMeToDo = ()=>{
return (
    <BlogLayout>
        <Seo title="For Me To Do" />
        <h1 style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '0.2em',
        }} >
            <img src={ForMeToDoLogo} alt="For Me To Do Logo" title="For Me To Do Logo" style={{height: '1em'}}/>
            For Me To Do
        </h1>
        <ProjectSnapshot 
            projectName="For Me To Do"
            projectType="PWA"
            description={(<>
                <p>A ToDo List App with some handy features.</p>
                <ul>
                    <li><b>Progressive Web App (PWA)</b> - Installable as an App on both Mobile and Desktop devices, or simply visited as a website in the browser. <b>Save your Data locally</b> on your device, and keep using it even when <b>offline</b></li>
                    <li><b>Priority Rating</b> - Each Task is assigned a priority rating for easy management. Large Numbers sink to the bottom, and Low Numbers float to the top.</li>
                    <li><b>Task Description</b> - For when you need to remind yourself of a few important extra details or caveats about how that task should be completed.</li>
                </ul>
            </>)}
            href="https://for-me-to-do.web.app"
            readableLink="for-me-to-do.web.app"
            langList={[
                (<img src={logos.progLang.html} alt="HTML" title="HTML" />),
                (<img src={logos.progLang.scss} alt="SCSS" title="SCSS" />),
                (<img src={logos.progLang.javascript} alt="JavaScript" title="JavaScript" />),
            ]}  
            frameworkList={[
                (<img src={logos.frameworks.firebase} alt="Firebase" title="Firebase" />),
                (<img src={logos.frameworks.react} alt="React" title="React" />),
                (<img src={logos.frameworks.gatsby} alt="Gatsby" title="Gatsby" />),
            ]}
            previewImages={[
                (<StaticImage src="../images/projects/formetodo/preview01.png" alt="preview01" width={200} key="0" />),
                (<StaticImage src="../images/projects/formetodo/preview02.jpg" alt="preview02" width={200} key="1" />),
            ]}
        />
        <div className="blog-text-column">
            <h2>Motivation</h2>
            <p>The creation of this app served as an exploration into some new territories for me. Primarily it was used as an exercise to learn React/Gatsby, but along the way I also learnt;</p>
            <ul>
                <li>How to Store Data in IndexedDB (<em>with Persistant Storage</em>)</li>
                <li>How to Create a Progressive Web App</li>
                <li>How to Animate in React with Framer Motion</li>
            </ul>
        </div>
        <button><Link to="/">Back Home</Link></button>
    </BlogLayout>
)
}

export default project_ForMeToDo